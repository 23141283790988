/* stylelint-disable selector-max-compound-selectors */
@charset 'UTF-8';

.bottom-app-bar,
.bottom-app-bar-cell {
  --gap: calc(var(--gutter) / 2);
}

.bottom-app-bar {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) calc(var(--gap) * 3);
  place-content: center space-between;
}

.bottom-app-bar-cell {
  --font-size: var(--font-size-body-extra-small);

  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: var(--font-size);
  line-height: var(--font-size);
}

.bottom-app-bar-cell-fine-print {
  border-top: 1px solid var(--color-midnight-blue-600);
  flex-basis: 100%;
  line-height: var(--type-scale-golden-ratio);
  padding-top: var(--gap);
}

/* ----------------------------------------------- */
.bottom-app-bar-cell-social,
.bottom-app-bar-cell-nav {
  flex-grow: 0;
  flex-shrink: 0;
}

/* ----------------------------------------------- */
.copyright-sign-off-device,
.copyright-sign-off-device-cell,
.copyright-sign-off-device-cell-monogram svg {
  --monogram-size: 50px;
}

.copyright-sign-off-device a {
  color: var(--color-white);
  text-decoration: none;
}

.copyright-sign-off-device {
  align-items: flex-start;
  column-gap: calc(var(--gutter) * 0.75);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-quicksand-regular);
  font-size: var(--font-size-body-extra-small);
  line-height: var(--type-scale-perfect-fourth);
  place-content: flex-start flex-start;
  text-shadow: var(--text-shadow-midnight-blue-dark);
}

.copyright-sign-off-device span {
  display: inline;
  font-size: unset;
  line-height: unset;
}

.copyright-sign-off-device-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  order: 1;
}

.copyright-sign-off-device-cell-fine-print {
  padding-top: 2px;
}

/* ----------------------------------------------- */
.copyright-sign-off-device-cell-monogram {
  aspect-ratio: 1 / 1;
  flex-basis: var(--monogram-size);
  flex-grow: 0;
  flex-shrink: 0;
  order: 0;
}

.copyright-sign-off-device-cell-monogram svg {
  margin-top: calc(var(--monogram-size) * 0.15 * -1);
}

.copyright-sign-off-device-cell-monogram svg.favicon .hallmark-left {
  fill: var(--color-cobalt-blue-600);
}

.copyright-sign-off-device-cell-monogram svg.favicon .hallmark-right {
  fill: var(--color-midnight-blue-500);
}

/* ----------------------------------------------- */
.footer-links {
  --font-size: var(--font-size-body-extra-small);

  align-items: center;
  color: var(--color-white);
  column-gap: var(--gap);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: var(--font-family-havelock-black);
  font-size: var(--font-size);
  line-height: var(--font-size);
  list-style: none;
  margin: 0;
  padding: 0;
  place-content: flex-start flex-start;
}

.footer-links li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.footer-links li:not(.territory-icon) {
  margin-right: var(--gap);
}

.footer-links svg {
  --default-icon-height: 16px;

  fill: currentcolor;
  height: var(--default-icon-height);
}

/* .footer-links svg.united-states {
  height: calc(var(--default-icon-height) * 0.65);
} */

.footer-links svg.global {
  height: calc(var(--default-icon-height) * 1);
}

/* ----------------------------------------------- */

/* stylelint-disable-next-line no-descending-specificity */
.footer-links a,
.footer-links button {
  color: var(--color-white);
  text-shadow: var(--text-shadow-midnight-blue-dark);
  transition-duration: var(--speed-normal);
  transition-property: color;
  transition-timing-function: var(--easing-color-or-opacity);
}

.footer-links a:hover,
.footer-links button:hover {
  color: var(--color-cobalt-blue-500);
  transition-duration: var(--speed-extra-fast);
}

.footer-links a:active,
.footer-links button:active,
.footer-links a:focus,
.footer-links button:focus {
  color: var(--color-cobalt-blue-300);
  text-shadow: none;
  transition-property: none;
}

/* ----------------------------------------------- */
@media (width <= 480px) {
  .bottom-app-bar {
    justify-content: space-around;
  }

  .bottom-app-bar-cell-nav {
    flex-basis: 100%;
  }

  .footer-links {
    column-gap: calc(var(--gap) * 2);
    margin-top: var(--gap);
    place-content: center center;
    text-align: center;
  }

  .footer-links li:not(.territory-icon) {
    margin-right: unset;
  }

  .bottom-app-bar ul.social {
    column-gap: calc(var(--gutter) * 0.5);
  }

  .social.follow-buttons::before {
    display: none;
  }

  .copyright-sign-off-device {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    row-gap: 0;
    text-align: center;
  }

  .copyright-sign-off-device-cell-monogram svg {
    height: var(--monogram-size);
    margin-top: 0;
    width: var(--monogram-size);
  }
}
