@charset 'UTF-8';

a.inline-text-button-link {
  --decoration-height: 2px;
  --decoration-color: var(--color-midnight-blue-900);
  --surface-color: transparent;
  --on-surface-color: var(--color-midnight-blue-900);
  --outline-width: 1px;

  background-color: var(--surface-color);
  background-image: linear-gradient(
    to right,
    var(--decoration-color) 0,
    var(--decoration-color) 100%
  );
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0 var(--decoration-height);
  border-radius: var(--rounded-corners-small);
  color: var(--on-surface-color);
  display: inline-block;
  outline-color: transparent;
  outline-offset: calc(var(--outline-width) * 2);
  outline-style: solid;
  outline-width: var(--outline-width);
  padding-bottom: var(--decoration-height);
  padding-left: calc(var(--decoration-height) * 2);
  padding-right: calc(var(--decoration-height) * 2);
  padding-top: var(--decoration-height);
  transition-duration: var(--speed-fast);
  transition-property: background-size;
  transition-timing-function: var(--easing-exiting);
}

.top-app-bar a.inline-text-button-link {
  text-shadow: var(--text-shadow-white);
}

a.inline-text-button-link:hover {
  background-size: 100% var(--decoration-height);
}

a.inline-text-button-link:active,
a.inline-text-button-link:focus,
a.inline-text-button-link[aria-current="page"] {
  --decoration-color: var(--color-cobalt-blue-500);
  --surface-color: var(--color-cobalt-blue-600);
  --on-surface-color: var(--color-white);

  outline-color: var(--decoration-color);
  transition-property: none;
}

.top-app-bar a.inline-text-button-link:active,
.top-app-bar a.inline-text-button-link:focus,
.top-app-bar a.inline-text-button-link[aria-current="page"] {
  text-shadow: var(--text-shadow-cobalt-blue-dark);
}

/* ----------------------------------------------- */
.button-with-brand-angle,
.button-with-brand-angle::after,
.button-with-brand-angle::before {
  --font-size: var(--font-size-body-fluid);
  --surface-color: var(--color-neon-rose-100);
  --on-surface-color: var(--color-neon-rose-600);

  background-color: var(--surface-color);
}

/* ----------------------------------------------- */
.button-with-brand-angle {
  --padding: calc(var(--font-size) / 2);

  align-items: center;
  appearance: none;
  border: none;
  color: var(--on-surface-color);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-havelock-black);
  font-size: var(--font-size);
  letter-spacing: var(--letter-spacing-tight);
  line-height: 18px;
  outline: none;
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: 0;
  padding-top: var(--padding);
  place-content: center flex-start;
  position: relative;
  text-shadow: var(--text-shadow-white);
}

.button-with-brand-angle.with-margin {
  --vertical-margin: calc(var(--gutter) / 2);

  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
}

/* ----------------------------------------------- */
.button-with-brand-angle::after,
.button-with-brand-angle::before {
  --horizontal-offset-hack: 1px;
  --width: calc(var(--font-size) * 2);

  aspect-ratio: 1 / 1;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateX(0);
  transition-duration: var(--speed-extra-fast);
  transition-property: transform;
  transition-timing-function: var(--easing-exiting);
  width: var(--width);
  z-index: -1;
}

.button-with-brand-angle::after {
  /* Top border is an odd hack to achieve the angle with visual crispness */
  border-top: 1px solid var(--surface-color);
  clip-path: polygon(0 0, 100% 0, var(--hero-angle) 100%, 0% 100%);
  right: calc(var(--width) * -1);
}

.button-with-brand-angle::before {
  /* right: 0; */
  right: calc(var(--horizontal-offset-hack) * -1);
}

/* ----------------------------------------------- */
.button-with-brand-angle:hover,
.button-with-brand-angle:hover::before,
.button-with-brand-angle:hover::after {
  --surface-color: var(--color-neon-rose-050);
  --on-surface-color: var(--color-neon-rose-500);
}

.button-with-brand-angle:active,
.button-with-brand-angle:focus,
.button-with-brand-angle:active::after,
.button-with-brand-angle:active::before,
.button-with-brand-angle:focus::after,
.button-with-brand-angle:focus::before {
  --surface-color: var(--color-neon-rose-050);
  --on-surface-color: var(--color-neon-rose-500);

  /* stylelint-disable-next-line declaration-no-important */
  text-shadow: none !important;
  transition-property: none;
}

.button-with-brand-angle:hover::after,
.button-with-brand-angle:hover::before {
  transform: translateX(calc(var(--width) - var(--horizontal-offset-hack)));
  transition-timing-function: var(--easing-entering);
}

/* ----------------------------------------------- */
p a {
  --text-color: var(--color-black);
  --text-underline-color: var(--color-neon-rose-500);
  --text-underline-hover-color: var(--color-cobalt-blue-500);
  --text-underline-height: 2px;
  --text-underline-width: 100%;
  --text-underline-hover-width: 0;

  appearance: none;
  background-color: transparent;
  background-image: linear-gradient(
      to right,
      var(--text-underline-hover-color) 0,
      var(--text-underline-hover-color) var(--text-underline-width)
    ),
    linear-gradient(
      to right,
      var(--text-underline-color) 0,
      var(--text-underline-color) var(--text-underline-width)
    );
  background-position:
    0 var(--text-underline-width),
    50% var(--text-underline-width);
  background-repeat: no-repeat;
  background-size:
    var(--text-underline-hover-width) var(--text-underline-height),
    var(--text-underline-width) var(--text-underline-height);
  border: none;
  color: var(--text-color);
  cursor: pointer;
  display: inline;
  margin: 0;
  padding-bottom: calc(var(--text-underline-height) * 2);
  padding-left: 0;
  padding-right: 0;
  padding-top: var(--text-underline-height);
  transition-duration: var(--speed-normal);
  transition-property: background-size;
  transition-timing-function: var(--easing-entering);
}

p a.strong-call-to-action {
  --text-color: var(--color-neon-rose-500);

  font-family: var(--font-family-quicksand-semi-bold);
}

p a:hover {
  --text-color: var(--color-cobalt-blue-500);
  --text-underline-hover-width: 100%;

  transition-duration: var(--speed-extra-fast);
}

p a:active,
p a:focus {
  background-color: var(--text-underline-hover-color);
  background-image: none;
  color: var(--color-white);
  text-shadow: var(--text-shadow-neon-rose-dark);
  transition-property: none;
}

/* ----------------------------------------------- */
.link-with-image,
.link-with-image span {
  --image-width: 100px;
}

.link-with-image {
  --gap: var(--gutter);

  align-items: flex-start;
  color: var(--color-neon-rose-1000);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: var(--font-family-quicksand-bold);
  gap: calc(var(--gap) / 2) var(--gap);
  place-content: flex-start flex-start;
}

.link-with-image span {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  order: 0;
}

.link-with-image span.image {
  flex-basis: var(--image-width);
  flex-grow: 0;
  flex-shrink: 0;
}

.link-with-image span.label {
  /* flex-basis: calc(100% - var(--image-width) - var(--gap)); */
  flex-basis: 100%;
  line-height: var(--type-scale-perfect-fourth);
}

.link-with-image span.image svg {
  fill: currentcolor;
  width: var(--image-width);
}
