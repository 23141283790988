/* stylelint-disable no-descending-specificity */
/* stylelint-disable selector-max-compound-selectors */
@charset 'UTF-8';

.organization-registration-details,
.fine-print-links {
  --gap: calc(var(--gutter) / 2);

  display: inline;
  margin-bottom: unset;
  margin-top: unset;
  padding: unset;
}

.organization-registration-details {
  margin-right: var(--gap);
}

.organization-registration-details dt,
.organization-registration-details dd,
.fine-print-links li {
  display: inline;
  margin: unset;
}

.organization-registration-details dt::after {
  content: " ";
}

.fine-print-links {
  column-gap: var(--gap);
  display: inline-flex;
}

/* ----------------------------------------------- */
.key-aspects {
  --vertical-margin: var(--gutter);

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  list-style: none;
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
  padding: 0;
  place-content: flex-start flex-start;
  row-gap: 2px;
}

.key-aspects li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.key-aspects dl,
.key-aspects dt,
.key-aspects dd {
  --term-width: 25%;
  --term-minimum-width: 20ch;
}

.key-aspects dl {
  align-items: stretch;

  /* column-gap: var(--gutter); */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 0;
  margin-top: 0;
  place-content: stretch flex-start;
}

.key-aspects dt,
.key-aspects dd {
  --surface-color: var(--color-cobalt-blue-700);
  --on-surface-color: var(--color-white);
  --padding: calc(var(--gutter) / 4);

  align-self: auto;
  background-color: var(--surface-color);
  color: var(--on-surface-color);
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  padding-bottom: calc(var(--padding) * 2);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  text-shadow: var(--text-shadow-cobalt-blue-dark);
}

.key-aspects dt {
  flex-basis: var(--term-width);
  flex-shrink: 0;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size-body-big-fluid);
  min-width: var(--term-minimum-width);
  padding-left: calc(var(--padding) * 2);

  /* white-space: nowrap; */
  width: var(--term-width);
}

.key-aspects dd {
  --surface-color: var(--color-cobalt-blue-050);
  --on-surface-color: var(--color-cobalt-blue-1000);

  font-family: var(--font-family-quicksand-medium);
  line-height: var(--type-scale-golden-ratio);
  padding-left: calc(var(--padding) * 2);
  padding-right: calc(var(--padding) * 4);
  padding-top: calc(var(--padding) + 0.05em);
  text-shadow: var(--text-shadow-white);
}

.key-aspects dt.alternate {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-900);

  text-shadow: var(--text-shadow-white);
}

.key-aspects dd.alternate {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-1000);

  padding-left: 0;

  /* padding-left: var(--padding); */
  padding-right: var(--padding);
  padding-top: var(--padding);
}

@media (width <= 540px) {
  .key-aspects dl,
  .key-aspects dt,
  .key-aspects dd {
    --term-width: 100%;
    --term-minimum-width: 20ch;
  }

  .key-aspects dl {
    flex-wrap: wrap;
  }

  .key-aspects dt {
    padding-bottom: var(--padding);
  }

  .key-aspects dd.alternate {
    padding-right: 0;
    padding-top: 0;
  }

  .key-aspects dd.alternate .link-with-image {
    margin-left: calc(var(--gutter) / 2);
    margin-right: calc(var(--gutter) / 2);
  }
}

/* ----------------------------------------------- */
.leadership-team-list,
.leadership-team-list li {
  --gap: calc(var(--gutter) / 4);
  --columns-per-row: 3;
  --team-member-width: calc(100% / var(--columns-per-row));
  --team-member-width-adjustment-factor: calc(
    (var(--columns-per-row) - 1) / var(--columns-per-row)
  );
}

@media (width <= 540px) {
  .leadership-team-list,
  .leadership-team-list li {
    --columns-per-row: 2;
  }
}

@media (width <= 375px) {
  .leadership-team-list,
  .leadership-team-list li {
    --columns-per-row: 1;
  }
}

.leadership-team-list {
  --vertical-margin: calc(var(--gutter) / 2);

  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) var(--gap);
  list-style: none;
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
  padding: 0;
  place-content: stretch flex-start;
  text-align: center;
}

.leadership-team-list li {
  --padding: calc(var(--gap) * 2);
  --surface-color: var(--color-cobalt-blue-050);
  --width: calc(var(--team-member-width) - var(--gap) * var(--team-member-width-adjustment-factor));

  align-self: auto;
  background-color: var(--surface-color);
  flex-basis: var(--width);
  flex-grow: 0;
  flex-shrink: 1;
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  text-shadow: var(--text-shadow-white);
}

/* ----------------------------------------------- */
.leadership-team-definition-list,
.leadership-team-definition-list dd {
  --gap: calc(var(--gutter) / 4);
}

.leadership-team-definition-list {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  margin-bottom: 0;
  margin-top: 0;
  place-content: flex-start flex-start;
  row-gap: var(--gap);
}

.leadership-team-definition-list dt {
  display: none;
}

.leadership-team-definition-list dd {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  order: 1;
}

.leadership-team-definition-list dd.image {
  aspect-ratio: 1 / 1;
  margin-bottom: calc(var(--gap) * 1.5);
  order: 0;
}

.leadership-team-definition-list dd.image img {
  background-color: var(--color-white);
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
}

.leadership-team-definition-list .name,
.leadership-team-definition-list .job-title {
  --font-size: var(--font-size-h6-fluid);

  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size);
  line-height: var(--font-size);
}

.leadership-team-definition-list .job-title {
  --font-size: var(--font-size-body-small-fluid);
}

.leadership-team-definition-list .about,
.leadership-team-definition-list .about::before {
  --decoration-height: 1px;
}

.leadership-team-definition-list .about {
  flex-grow: 1;
  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size-body-small);
  line-height: var(--type-scale-perfect-fourth);
  margin-top: calc(var(--gap) / 2);
  padding-top: calc(var(--gap) + var(--decoration-height));
  position: relative;
}

.leadership-team-definition-list .about::before {
  --decoration-color: var(--color-cobalt-blue-200);
  --decoration-width: calc(100% / 3);
  --decoration-position-x: calc(50% - var(--decoration-width) / 2);
  --decoration-position-y: 0;

  background-color: var(--decoration-color);
  content: "";
  height: var(--decoration-height);
  left: var(--decoration-position-x);
  position: absolute;
  top: var(--decoration-position-y);
  width: var(--decoration-width);
}

.leadership-team-definition-list .contact-points {
  margin-top: var(--gap);
}

/* ----------------------------------------------- */
.leadership-team-definition-list .contact-points ul,
.leadership-team-list .contact-points ul li {
  --social-icon-size: 24px;
}

.leadership-team-definition-list .contact-points ul {
  align-items: center;
  column-gap: calc(var(--social-icon-size) / 4);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  place-content: center center;
}

.leadership-team-list .contact-points ul li {
  align-self: auto;
  aspect-ratio: 1 / 1;
  background-color: unset;
  flex-basis: var(--social-icon-size);
  flex-grow: 0;
  flex-shrink: 0;
  height: var(--social-icon-size);
  padding-bottom: unset;
  padding-left: unset;
  padding-right: unset;
  padding-top: unset;
  width: var(--social-icon-size);
}

.leadership-team-list .contact-points svg {
  --freespace-margin: 8px;
  --size: calc(100% - var(--freespace-margin));
  --position-xy: calc(50% - (var(--size) / 2));

  aspect-ratio: 1 / 1;
  fill: currentcolor;
  height: var(--size);
  left: var(--position-xy);
  position: absolute;
  top: var(--position-xy);
  width: var(--size);
}

.leadership-team-list .contact-points a {
  --surface-color: var(--color-neon-rose-500);
  --on-surface-color: var(--color-white);
  --size: 100%;

  align-items: center;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--size);
  place-content: center center;
  position: relative;
  text-decoration: none;
  transition-duration: var(--speed-fast), var(--speed-fast), var(--speed-extra-fast);
  transition-property: background-color, box-shadow, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity),
    var(--easing-color-or-opacity);
  width: var(--size);
}

.leadership-team-list .contact-points a:hover {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-900);

  box-shadow: var(--shadow-elevation-medium);
  transition-timing-function: var(--easing-entering), var(--easing-entering), var(--easing-entering);
}

.leadership-team-list .contact-points a:active,
.leadership-team-list .contact-points a:focus {
  --surface-color: var(--color-moss-green-300);
  --on-surface-color: var(--color-moss-green-1000);

  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
  transition-property: none;
}

/* ----------------------------------------------- */
.advisers-list {
  --gap: calc(var(--gutter) / 2);

  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) var(--gap);
  list-style: none;
  margin-bottom: var(--gap);
  margin-top: var(--gap);
  padding: 0;
  place-content: flex-start flex-start;
}

.advisers-list li {
  --item-width: calc(25% - var(--gap));

  align-self: auto;
  flex-basis: var(--item-width);
  flex-grow: 0;
  flex-shrink: 1;
  max-width: 128px;
}

@media (width <= 768px) {
  .advisers-list li {
    --item-width: calc(100% / 3 - var(--gap));

    max-width: unset;
  }
}

@media (width <= 540px) {
  .advisers-list li {
    --item-width: calc(50% - var(--gap));
  }
}

.advisers-list dl {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0;
  place-content: flex-start flex-start;
  position: relative;
  row-gap: calc(var(--gutter) / 4);
}

.advisers-list dt {
  display: none;
}

.advisers-list dd {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  order: 1;
  padding: 0;
}

.advisers-list dd.image {
  aspect-ratio: 1 / 1;
  background-color: var(--color-white);
  order: 0;
  position: relative;
  width: 100%;
}

.advisers-list dd.image img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: block;
  height: var(--media-size);
  left: var(--media-position);
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
  z-index: 0;
}

.advisers-list dd.image svg {
  fill: var(--color-midnight-blue-900);
}

.advisers-list dd.name,
.advisers-list dd.name span {
  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size-body-small);
  line-height: var(--type-scale-perfect-fourth);
}

.advisers-list dd.name span {
  display: block;
}

.advisers-list dd.name a {
  color: var(--color-black);
}

.advisers-list dd.image a {
  --size: 100%;

  border-color: var(--color-black);
  border-style: solid;
  border-width: 1px;
  display: block;
  height: var(--size);
  position: relative;
  width: var(--size);
}

.advisers-list dd.image a:hover {
  border-color: var(--color-cobalt-blue-600);
}

.advisers-list dd.image a:active,
.advisers-list dd.image a:focus {
  border-color: var(--color-cobalt-blue-900);
}

.advisers-list dd.linkedin-url {
  --icon-size: 20px;
  --position-xy: 0;

  bottom: var(--position-xy);
  flex-basis: var(--icon-size);
  height: var(--icon-size);
  position: absolute;
  right: var(--position-xy);
  transform: translateY(calc(var(--icon-size) * 0.75 * -1));
  width: var(--icon-size);
}

.advisers-list dd.linkedin-url a {
  --surface-color: var(--color-neon-rose-100);
  --on-surface-color: var(--color-neon-rose-700);
  --size: 100%;

  align-items: center;
  background-color: var(--surface-color);
  border-radius: var(--rounded-corners-small);
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: var(--size);
  place-content: center center;
  position: relative;
  text-decoration: none;
  transition-duration: var(--speed-fast), var(--speed-extra-fast);
  transition-property: background-color, color;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity);
  width: var(--size);
}

dd.linkedin-url svg {
  --freespace-margin: 8px;
  --size: calc(100% - var(--freespace-margin));
  --position-xy: calc(50% - (var(--size) / 2));

  aspect-ratio: 1 / 1;
  fill: currentcolor;
  height: var(--size);
  left: var(--position-xy);
  position: absolute;
  top: var(--position-xy);
  width: var(--size);
}

/* ----------------------------------------------- */
.advisers-list dd.linkedin-url a:hover {
  --surface-color: var(--color-cobalt-blue-200);
  --on-surface-color: var(--color-cobalt-blue-900);

  transition-timing-function: var(--easing-entering), var(--easing-entering);
}

.advisers-list dd.linkedin-url a:active,
.advisers-list dd.linkedin-url a:focus {
  --surface-color: var(--color-moss-green-300);
  --on-surface-color: var(--color-moss-green-1000);

  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: none !important;
  transition-property: none;
}

/* ----------------------------------------------- */
ol[itemtype="https://schema.org/BreadcrumbList"],
ol[itemtype="https://schema.org/BreadcrumbList"] a
{
  --on-surface-color: var(--color-midnight-blue-950);

  color: var(--on-surface-color);
  text-shadow: var(--text-shadow-white);
}

ol[itemtype="https://schema.org/BreadcrumbList"],
ol[itemtype="https://schema.org/BreadcrumbList"] span,
ol[itemtype="https://schema.org/BreadcrumbList"] li
{
  --font-size: var(--font-size-body-extra-small);
  --gap: calc(var(--font-size) * 1.5);

  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size);
  line-height: var(--font-size);
}

ol[itemtype="https://schema.org/BreadcrumbList"]
{
  --padding: calc(var(--gap) / 3);
  --surface-color: var(--color-midnight-blue-050);
  --vertical-offset: calc(var(--gap) * 2.25 * -1);

  align-items: center;
  background-color: var(--surface-color);

  /* border-bottom: 2px solid var(--color-midnight-blue-200); */
  column-gap: var(--gap);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  left: auto;
  list-style: none;
  margin-bottom: var(--gap);
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  place-content: flex-start flex-start;
  position: absolute;
  top: auto;
  transform: translateY(var(--vertical-offset));
}

@media (width <= 768px) {
  ol[itemtype="https://schema.org/BreadcrumbList"]
  {
    --vertical-offset: 0;

    position: relative;
  }
}

ol[itemtype="https://schema.org/BreadcrumbList"] li
{
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

ol[itemtype="https://schema.org/BreadcrumbList"] li:first-child
{
  display: none;
}

ol[itemtype="https://schema.org/BreadcrumbList"] li:not(:last-child):after
{
  content: "/";
  position: absolute;
  right: calc(var(--gap) * 0.7 * -1);
}

ol[itemtype="https://schema.org/BreadcrumbList"] [aria-current="page"] a
{
  pointer-events: none;
}

/* ----------------------------------------------- */
.donation-region-tabs {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(var(--gutter) / 2) var(--gutter);
  place-content: center space-between;
}

.donation-region-tabs-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.donation-region-tabs-cell-header {
  flex-grow: 1;
  flex-shrink: 1;
}

.donation-region-tabs .header {
  margin-bottom: unset;
  margin-top: -5px;
}

/* ----------------------------------------------- */
.donation-region-list,
.donation-region-list li,
.donation-region-list li a {
  --icon-height: 18px;
}

.donation-region-list {
  --font-size: 11px;

  align-items: center;
  column-gap: calc(var(--gutter) * 0.75);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size);
  letter-spacing: var(--letter-spacing-all-caps);
  line-height: var(--font-size);
  list-style: none;
  margin: 0;
  padding: 0;
  place-content: center flex-end;
}

.donation-region-list li {
  align-self: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.donation-region-list li a,
.donation-region-list li a svg {
  display: flex;
}

.donation-region-list svg {
  fill: currentcolor;
  height: var(--icon-height);
  width: auto;
}

.donation-region-list span {
  font-size: unset;
  line-height: unset;
}

.donation-region-list .global svg path.foreground {
  fill: var(--color-white);
}

/* ----------------------------------------------- */
.donation-region-list li a {
  --gap: calc(var(--icon-height) / 8);
  --on-surface-color: var(--color-cobalt-blue-500);
  --underline-color: transparent;

  align-items: center;
  border-bottom: 2px solid var(--underline-color);
  color: var(--on-surface-color);
  flex-direction: column;
  flex-wrap: nowrap;
  padding-bottom: var(--gap);
  place-content: stretch flex-start;
  row-gap: calc(var(--gap) * 3);
  transition-duration: var(--speed-extra-fast);
  transition-property: color;
  transition-timing-function: var(--easing-color-or-opacity);
}

.donation-region-list li a:hover,
.donation-region-list li a[data-is-selected="true"] {
  --on-surface-color: var(--color-neon-rose-500);
}

.donation-region-list li a:active,
.donation-region-list li a:focus {
  --on-surface-color: var(--color-cobalt-blue-500);

  transition-property: none;
}

.donation-region-list li a[data-is-selected="true"] {
  --underline-color: var(--color-neon-rose-500);
}

.donation-region-list li a span {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.donation-region-list li a span.icon {
  flex-grow: 1;
}

@media (width <= 540px) {
  .donation-region-tabs {
    flex-direction: column;
    text-align: center;
  }

  .donation-region-tabs-cell-header {
    order: 1;
  }
}
