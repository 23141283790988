/* stylelint-disable declaration-no-important */
@charset 'UTF-8';

:root {
  --animation-delay-short: 0.1s;
  --animation-delay-medium: 0.5s;
  --color-black: black;
  --color-white: white;
  --color-error: #991b1b;
  --color-success: #166534;

  /* Grey */
  --color-grey-900: #17191b;
  --color-grey-800: #37393b;
  --color-grey-700: #56575a;
  --color-grey-600: #696b6e;
  --color-grey-500: #919396;

  /* Base: 400 */
  --color-grey-400: #b1b3b6;
  --color-grey-300: #d5d7da;
  --color-grey-200: #e5e7eb;
  --color-grey-100: #eff1f4;
  --color-grey-050: #f7f9fc;

  /* Moss Green */
  --color-moss-green-910: #676d18; /* hsl(64, 64%, 26%) */
  --color-moss-green-920: #636817; /* hsl(64, 64%, 25%) */
  --color-moss-green-930: #5f6416; /* hsl(64, 64%, 24%) */
  --color-moss-green-940: #5b6015; /* hsl(64, 64%, 23%) */
  --color-moss-green-950: #575c14; /* hsl(64, 64%, 22%) */
  --color-moss-green-960: #535813; /* hsl(64, 64%, 21%) */
  --color-moss-green-970: #4f5312; /* hsl(64, 64%, 20%) */
  --color-moss-green-980: #4b4f11; /* hsl(64, 64%, 19%) */
  --color-moss-green-990: #474b10; /* hsl(64, 64%, 18%) */
  --color-moss-green-1000: #43470f; /* hsl(64, 64%, 17%) */
  --color-moss-green-900: #6f7319;
  --color-moss-green-800: #889927;
  --color-moss-green-700: #96b030;

  /* Base: 600 */
  --color-moss-green-600: #a5c639;
  --color-moss-green-500: #b0d840;
  --color-moss-green-400: #b0d840;
  --color-moss-green-300: #c6e478;
  --color-moss-green-200: #d5ec9e;
  --color-moss-green-100: #e6f3c4;
  --color-moss-green-050: #f5fbe7;

  /* Midnight Blue */
  --color-midnight-blue-910: #173462; /* hsl(215, 58%, 25%) */
  --color-midnight-blue-920: #15305a; /* hsl(215, 58%, 24%) */
  --color-midnight-blue-930: #122c53; /* hsl(215, 58%, 23%) */
  --color-midnight-blue-940: #10284b; /* hsl(215, 58%, 22%) */
  --color-midnight-blue-950: #0e2444; /* hsl(215, 58%, 21%) */
  --color-midnight-blue-960: #0c203d; /* hsl(215, 58%, 20%) */
  --color-midnight-blue-970: #091c36; /* hsl(215, 58%, 19%) */
  --color-midnight-blue-980: #07182e; /* hsl(215, 58%, 18%) */
  --color-midnight-blue-990: #051427; /* hsl(215, 58%, 17%) */
  --color-midnight-blue-1000: #031020; /* hsl(215, 58%, 16%) */

  /* Base: 900 */
  --color-midnight-blue-900: #1b3b69;
  --color-midnight-blue-800: #285789;
  --color-midnight-blue-700: #30679a;
  --color-midnight-blue-600: #3a77ab;
  --color-midnight-blue-500: #4383b7;
  --color-midnight-blue-400: #5794c0;
  --color-midnight-blue-300: #6fa5ca;
  --color-midnight-blue-200: #93beda;
  --color-midnight-blue-100: #bbd7e9;
  --color-midnight-blue-050: #e3eff5;

  /* Cobalt Blue */

  --color-cobalt-blue-910: #255783; /* hsl(208, 56%, 33%) */
  --color-cobalt-blue-920: #245580; /* hsl(208, 56%, 32%) */
  --color-cobalt-blue-930: #23527b; /* hsl(208, 56%, 31%) */
  --color-cobalt-blue-940: #224f77; /* hsl(208, 56%, 30%) */
  --color-cobalt-blue-950: #214d74; /* hsl(208, 56%, 29%) */
  --color-cobalt-blue-960: #1f4a6f; /* hsl(208, 56%, 28%) */
  --color-cobalt-blue-970: #1e476b; /* hsl(208, 56%, 27%) */
  --color-cobalt-blue-980: #1d4567; /* hsl(208, 56%, 26%) */
  --color-cobalt-blue-990: #1c4364; /* hsl(208, 56%, 25%) */
  --color-cobalt-blue-1000: #1b4060; /* hsl(208, 56%, 24%) */

  /* Base: 900 */
  --color-cobalt-blue-900: #115d9f;
  --color-cobalt-blue-800: #177dc1;
  --color-cobalt-blue-700: #198ed5;
  --color-cobalt-blue-600: #1da1e9;
  --color-cobalt-blue-500: #20b0f8;
  --color-cobalt-blue-400: #36bcfa;
  --color-cobalt-blue-300: #56c8fa;
  --color-cobalt-blue-200: #85d8fc;
  --color-cobalt-blue-100: #b5e7fd;
  --color-cobalt-blue-050: #e2f6fe;

  /* Orange */
  --color-orange-910: #e03800; /* hsl(15, 100%, 44%) */
  --color-orange-920: #db3700; /* hsl(15, 100%, 43%) */
  --color-orange-930: #d63600; /* hsl(15, 100%, 42%) */
  --color-orange-940: #d13400; /* hsl(15, 100%, 41%) */
  --color-orange-950: #c30; /* hsl(15, 100%, 40%) */
  --color-orange-960: #c73200; /* hsl(15, 100%, 39%) */
  --color-orange-970: #c23000; /* hsl(15, 100%, 38%) */
  --color-orange-980: #bd2f00; /* hsl(15, 100%, 37%) */
  --color-orange-990: #b82e00; /* hsl(15, 100%, 36%) */
  --color-orange-1000: #b22d00; /* hsl(15, 100%, 35%) */
  --color-orange-900: #e53800;
  --color-orange-800: #ed5a00;
  --color-orange-700: #f26d00;
  --color-orange-600: #f77e00;
  --color-orange-500: #fa8b00;
  --color-orange-400: #fb9c00;
  --color-orange-300: #fdad13;

  /* Base: 200 */
  --color-orange-200: #ffc561;
  --color-orange-100: #ffdca1;
  --color-orange-050: #fff1d9;

  /* Neon Rose */

  --color-neon-rose-910: #940056; /* hsl(325, 100%, 29%) */
  --color-neon-rose-920: #8f0053; /* hsl(325, 100%, 28%) */
  --color-neon-rose-930: #8a0050; /* hsl(325, 100%, 27%) */
  --color-neon-rose-940: #85004d; /* hsl(325, 100%, 26%) */
  --color-neon-rose-950: #80004a; /* hsl(325, 100%, 25%) */
  --color-neon-rose-960: #7a0047; /* hsl(325, 100%, 24%) */
  --color-neon-rose-970: #750044; /* hsl(325, 100%, 23%) */
  --color-neon-rose-980: #700041; /* hsl(325, 100%, 22%) */
  --color-neon-rose-990: #6b003f; /* hsl(325, 100%, 21%) */
  --color-neon-rose-1000: #66003c; /* hsl(325, 100%, 20%) */
  --color-neon-rose-900: #9b005a;
  --color-neon-rose-800: #c10461;
  --color-neon-rose-700: #d60a65;
  --color-neon-rose-600: #ec0e69;

  /* Base: 500 */
  --color-neon-rose-500: #fe126c;
  --color-neon-rose-400: #fe3f84;
  --color-neon-rose-300: #ff649b;
  --color-neon-rose-200: #ff92b8;
  --color-neon-rose-100: #febdd5;
  --color-neon-rose-050: #ffe5ee;
  --color-neon-rose-010: #fff2f7;
  --window-shade: linear-gradient(180deg, white 0%, rgb(255 255 255 / 0%) 100%);
  --font-family-havelock-black: "havelock/titling-black", "Helvetica", "Arial", sans-serif;
  --font-family-havelock-bold: "havelock/titling-bold", "Helvetica", "Arial", sans-serif;
  --font-family-havelock-light: "havelock/titling-light", "Helvetica", "Arial", sans-serif;
  --font-family-havelock-medium: "havelock/titling-medium", "Helvetica", "Arial", sans-serif;
  --font-family-havelock-regular: "havelock/titling-regular", "Helvetica", "Arial", sans-serif;
  --font-family-quicksand-bold: "quicksand/bold", "Helvetica", "Arial", sans-serif;
  --font-family-quicksand-light: "quicksand/light", "Helvetica", "Arial", sans-serif;
  --font-family-quicksand-medium: "quicksand/medium", "Helvetica", "Arial", sans-serif;
  --font-family-quicksand-regular: "quicksand/regular", "Helvetica", "Arial", sans-serif;
  --font-family-quicksand-semi-bold: "quicksand/semi-bold", "Helvetica", "Arial", sans-serif;
  --font-size-h1: 96px;
  --font-size-h2: 60px;
  --font-size-h3: 48px;
  --font-size-h4: 34px;
  --font-size-h5: 24px;
  --font-size-h6: 20px;
  --font-size-body: 16px;
  --font-size-body-big: 23px;
  --font-size-body-small: 14px;
  --font-size-body-extra-small: 12px;
  --font-size-h1-fluid: clamp(3.355rem, 2.64rem + 3.586vw, 6.941rem);
  --font-size-h2-fluid: clamp(2.684rem, 2.178rem + 2.53vw, 5.214rem);
  --font-size-h3-fluid: clamp(2.145rem, 1.793rem + 1.76vw, 3.905rem);
  --font-size-h4-fluid: clamp(1.716rem, 1.474rem + 1.21vw, 2.936rem);
  --font-size-h5-fluid: clamp(1.375rem, 1.21rem + 0.825vw, 2.2rem);
  --font-size-h6-fluid: clamp(1.1rem, 0.99rem + 0.55vw, 1.65rem);
  --font-size-body-fluid: clamp(0.88rem, 0.814rem + 0.363vw, 1.243rem);
  --font-size-body-big-fluid: clamp(0.968rem, 0.8954rem + 0.3993vw, 1.3673rem);
  --font-size-body-small-fluid: clamp(0.704rem, 0.66rem + 0.22vw, 0.924rem);
  --font-size-button: 18px;
  --gutter: 2rem;
  --hero-angle: 37.5%;
  --text-shadow-white: hsl(0deg 0% 100% / 75%) 0 1px 1px;
  --text-shadow-black: hsl(0deg 0% 0% / 75%) 0 1px 1px;
  --text-shadow-moss-green: hsl(64deg 64% 27% / 50%) 0 1px 1px;
  --text-shadow-moss-green-dark: hsl(64deg 64% 13% / 75%) 0 1px 1px;
  --text-shadow-midnight-blue: hsl(216deg 42% 26% / 50%) 0 1px 1px;
  --text-shadow-midnight-blue-dark: hsl(216deg 42% 13% / 75%) 0 1px 1px;
  --text-shadow-cobalt-blue: hsl(207deg 87% 35% / 50%) 0 1px 1px;
  --text-shadow-cobalt-blue-dark: hsl(207deg 87% 17% / 75%) 0 1px 1px;
  --text-shadow-orange: hsl(15deg 100% 45% / 50%) 0 1px 1px;
  --text-shadow-orange-dark: hsl(15deg 100% 22% / 75%) 0 1px 1px;
  --text-shadow-neon-rose: hsl(327deg 100% 30% / 50%) 0 1px 1px;
  --text-shadow-neon-rose-dark: hsl(327deg 100% 15% / 75%) 0 1px 1px;
}

@font-face {
  font-display: fallback;
  font-family: "havelock/titling-black";
  src: url("/fonts/havelock/titling-black.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "havelock/titling-bold";
  src: url("/fonts/havelock/titling-bold.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "havelock/titling-light";
  src: url("/fonts/havelock/titling-light.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "havelock/titling-medium";
  src: url("/fonts/havelock/titling-medium.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "havelock/titling-regular";
  src: url("/fonts/havelock/titling-regular.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "quicksand/bold";
  src: url("/fonts/quicksand/bold.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "quicksand/dash";
  src: url("/fonts/quicksand/dash.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "quicksand/light-oblique";
  src: url("/fonts/quicksand/light-oblique.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "quicksand/light";
  src: url("/fonts/quicksand/light.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "quicksand/medium";
  src: url("/fonts/quicksand/medium.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "quicksand/regular";
  src: url("/fonts/quicksand/regular.woff2") format("woff2");
}

@font-face {
  font-display: fallback;
  font-family: "quicksand/semi-bold";
  src: url("/fonts/quicksand/semi-bold.woff2") format("woff2");
}

[data-document-ready="false"] *,
[data-document-ready="false"] *::after,
[data-document-ready="false"] *::before {
  animation-play-state: paused !important;
}

@media (prefers-reduced-motion) {
  [data-document-ready="true"] *,
  [data-document-ready="true"] *::after,
  [data-document-ready="true"] *::before {
    animation-play-state: paused !important;
  }
}

/* ----------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
legend {
  margin-bottom: calc(var(--gutter) / 2);
  margin-top: calc(var(--gutter) / 2);
}

/* ----------------------------------------------- */
strong {
  font-family: var(--font-family-quicksand-bold);
  font-style: normal;
}

/* ----------------------------------------------- */
h1,
h2,
h3 {
  color: var(--color-neon-rose-500);
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size-h4-fluid);
}

h1 {
  font-family: var(--font-family-quicksand-medium);
  line-height: var(--type-scale-major-second);
  margin-top: 0;
}

h2 {
  font-size: var(--font-size-h6-fluid);
}

h3 {
  font-size: var(--font-size-body-big-fluid);
}

p,
span {
  font-size: var(--font-size-body-fluid);
  line-height: var(--type-scale-golden-ratio);
}

/* ----------------------------------------------- */
h1[data-color-scheme="neon-rose"],
h2[data-color-scheme="neon-rose"],
h3[data-color-scheme="neon-rose"] {
  color: var(--color-neon-rose-900);
}

/* ----------------------------------------------- */

.small-text {
  font-size: var(--font-size-body-small);
}

hr {
  --ruler-height: 2px;
  --vertical-margin: var(--gutter);

  background-color: var(--color-neon-rose-500);
  border: none;
  height: var(--ruler-height);
  margin-bottom: var(--vertical-margin);
  margin-top: var(--vertical-margin);
  padding: 0;
  width: 100%;
}

hr.big {
  margin-top: calc(var(--vertical-margin) * 2);
}

hr.small {
  height: 1px;
}

hr.is-same-section {
  background-color: transparent;
  border-top: 1px dashed var(--color-red-400);
}

hr.is-same-section.solid {
  --vertical-margin: calc(var(--gutter) * 1.5);

  border-top: 1px solid var(--color-red-400);
}

/* ----------------------------------------------- */
svg.favicon .hallmark-left {
  fill: var(--color-cobalt-blue-900);
}

svg.favicon .hallmark-right {
  fill: var(--color-neon-rose-500);
}

/* ----------------------------------------------- */
.button-unstyled,
.button-unstyled:hover,
.button-unstyled:active,
.button-unstyled:focus {
  appearance: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  transition-property: none;
}

/* ----------------------------------------------- */
.contact-points a {
  color: var(--color-black);
}

/* ----------------------------------------------- */
p.lead-paragraph {
  font-family: var(--font-family-quicksand-semi-bold);
  font-size: var(--font-size-body-big-fluid);
}

p.big {
  font-size: var(--font-size-h6-fluid);
}

p.extra-small {
  font-size: var(--font-size-body-extra-small);
}

/* ----------------------------------------------- */
ul.simple-text-list {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: var(--font-family-quicksand-medium);
  font-size: var(--font-size-body-fluid);
  line-height: var(--type-scale-golden-ratio);
  place-content: flex-start flex-start;
  row-gap: calc(var(--gutter) / 2);
}

ul.simple-text-list li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
}

/* ----------------------------------------------- */
.blockquote,
.blockquote::after,
.blockquote::before {
  --surface-color: var(--color-neon-rose-100);

  background-color: var(--surface-color);
}

.blockquote {
  --blockquote-width: calc(100% / 3);
  --margin: calc(var(--gutter) * 1.5);
  --surface-color: var(--color-midnight-blue-500);
  --vertical-padding: calc(var(--gutter) * 0.75);

  color: var(--color-white);
  display: block;
  /* stylelint-disable-next-line property-disallowed-list */
  float: right;
  font-family: var(--font-family-quicksand-semi-bold);
  font-size: var(--font-size-body-big-fluid);
  line-height: var(--type-scale-golden-ratio);
  margin-bottom: var(--margin);
  margin-left: var(--margin);
  margin-right: 0;
  margin-top: calc(var(--gutter) * 0.25);
  padding-bottom: var(--vertical-padding);
  padding-left: calc(var(--gutter) * 1);
  padding-right: calc(var(--gutter) * 0.5);
  padding-top: var(--vertical-padding);
  position: relative;
  text-shadow: var(--text-shadow-midnight-blue-dark);
  width: var(--blockquote-width);
}

.blockquote.align-left {
  margin-left: 0;
  margin-right: var(--margin);
}

.blockquote .quote-mark {
  --font-size: var(--font-size-h4-fluid);
  --horizontal-offset: calc(var(--font-size) * 0.3);
  --vertical-offset: calc(var(--font-size) / 3 * -1);

  font-size: var(--font-size);
  position: absolute;
  transform: translateY(var(--vertical-offset));
}

.blockquote .quote-mark.quote-mark-open {
  left: var(--horizontal-offset);
}

.blockquote .quote-mark.quote-mark-close {
  margin-left: calc(var(--horizontal-offset) / 2);
}

.blockquote::after,
.blockquote::before {
  --surface-width: calc(var(--gutter) / 2.5);
  --position-y: calc(var(--surface-width) * -1);
  --offset: 2px;

  bottom: var(--position-y);
  content: "";
  display: block;
  position: absolute;
  right: calc(var(--surface-width) * -1);
  transform: translateX(var(--offset)) translateY(var(--offset));
  z-index: 0;
}

.blockquote::after {
  height: calc(100% - var(--surface-width));
  width: var(--surface-width);
}

.blockquote::before {
  height: var(--surface-width);
  width: calc(100% - var(--surface-width));
}

@media (width <= 768px) {
  .blockquote::after,
  .blockquote::before {
    --surface-width: calc(var(--gutter) / 4);
  }

  .blockquote {
    --blockquote-width: 90%;

    /* stylelint-disable-next-line property-disallowed-list */
    float: unset;
    margin-bottom: calc(var(--margin) * 0.75);
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(var(--margin) / 2);
  }
}

/* ----------------------------------------------- */
dl.postal-address,
dl.contact-points {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: var(--font-family-regular);
  line-height: var(--type-scale-golden-ratio);
  margin: 0;
  place-content: flex-start flex-start;
}

dl.contact-points {
  line-height: 18px;
  row-gap: calc(var(--gutter) / 3);
  white-space: nowrap;
}

dl.postal-address dt,
dl.contact-points dt {
  display: none;
}

dl.postal-address dd,
dl.contact-points dd {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 1;
  order: 0;
}

dl.contact-points dd {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 0;
}

.postal-address dd[itemprop="country"],
.postal-address dd[itemprop="streetAddress"] {
  flex-basis: 100%;
}

.postal-address dd[itemprop="addressRegion"],
.postal-address dd[itemprop="postalCode"] {
  --no-break-space: 0.3em;

  padding-left: var(--no-break-space);
}

.postal-address dd[itemprop="addressLocality"]::after {
  content: ",";
}

[itemtype="https://schema.org/Organization"] [itemprop="name"]
{
  color: var(--color-red-700);
  display: block;
  font-family: var(--font-family-bold);
  font-size: var(--font-size-h6-fluid);
  padding-bottom: 0.2em;
}

/* ----------------------------------------------- */
a.has-icon,
a.has-icon svg {
  --icon-size: 18px;
}

a.has-icon {
  align-items: center;
  column-gap: calc(var(--icon-size) / 2);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  line-height: var(--icon-size);
  place-content: center flex-start;
}

a.has-icon svg {
  align-self: auto;
  fill: cuurentcolor;
  flex-basis: var(--icon-size);
  flex-grow: 0;
  flex-shrink: 0;
  height: var(--icon-size);
  width: var(--icon-size);
}

/* ----------------------------------------------- */
.text-link,
button.text-link {
  --base-color: var(--color-red-700);
  --action-color: var(--color-red-100);
  --decoration-size: 2px;
  --track-length: 100%;
  --track-action-length: 0;

  appearance: none;
  background-color: transparent !important;
  background-image: linear-gradient(
      to right,
      var(--action-color) 0,
      var(--action-color) var(--track-length)
    ),
    linear-gradient(to right, var(--base-color) 0, var(--base-color) var(--track-length));
  background-position:
    0 var(--track-length),
    50% var(--track-length);
  background-repeat: no-repeat;
  background-size:
    var(--track-action-length) var(--decoration-size),
    var(--track-length) var(--decoration-size);
  border: none !important;
  color: var(--base-color) !important;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding-bottom: calc(var(--decoration-size) * 2.5) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: var(--decoration-size) !important;
  transition-duration: var(--speed-normal);
  transition-property: background-size;
  transition-timing-function: var(--easing-entering);
}

button.text-link {
  --font-size: inherit;

  border-radius: 0;
  box-shadow: none;
  color: var(--base-color);
  font-family: inherit;
  letter-spacing: 0;
  line-height: inherit;
  margin-left: 0;
  margin-right: 0;
  text-align: inherit;
  text-shadow: none;
  text-transform: none;
}

button.text-link:hover {
  background-color: transparent;
}

.text-link:hover,
button.text-link:hover {
  --track-action-length: 100%;

  transition-duration: var(--speed-extra-fast);
}

.text-link:active,
.text-link:focus,
button.text-link:active,
button.text-link:focus {
  --decoration-size: 1px;

  background-image: none;
  color: var(--base-color);
  outline-color: var(--base-color);
  outline-offset: 2px;
  outline-style: dashed;
  outline-width: var(--decoration-size);
  padding-bottom: var(--decoration-size);
  transition-property: none;
}

/* ----------------------------------------------- */
.page-section,
.page-section-cell {
  --column-gutter: calc(var(--gutter) * 1.5);
}

.page-section {
  align-items: flex-start;
  border-color: var(--color-neon-rose-500);
  border-style: solid;
  border-width: 1px 0 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: var(--gutter) var(--column-gutter);
  padding-bottom: var(--gutter);
  padding-top: var(--gutter);
  place-content: stretch flex-start;
  width: 100%;
}

.page-section:first-of-type {
  border-width: 0;
  padding-top: 0;
}

.page-section-two-col {
  flex-direction: row;
  flex-wrap: wrap;
}

@media (width <= 540px) {
  .page-section-two-col {
    flex-direction: column;
    flex-wrap: nowrap;
    row-gap: calc(var(--gutter) / 2);
  }
}

.page-section-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  order: 0;
}

.page-section-two-col .page-section-cell {
  --cell-width: 50%;

  flex-basis: calc(var(--cell-width) - var(--column-gutter));
}

.page-section-cell.header {
  margin-bottom: 0;
  margin-top: 0;
}

.page-section-cell p:first-of-type {
  margin-top: 0;
}

.page-section-two-col-with-header .page-section-cell.header {
  --cell-width: calc(100% / 3);

  /* --min-width: 200px; */
  --min-width: 20ch;
  --max-width: 414px;
  --preferred-width: 100%;

  flex-basis: clamp(var(--min-width), var(--cell-width), var(--max-width));
  flex-grow: 0;
  font-size: var(--font-size-h6-fluid);
}

.page-section-two-col-with-header .page-section-cell.header a {
  color: var(--color-neon-rose-500);
  transition-duration: var(--speed-normal);
  transition-property: color;
  transition-timing-function: var(--easing-color-or-opacity);
}

.page-section-two-col-with-header .page-section-cell.header a:hover,
.page-section-two-col-with-header .page-section-cell.header a:active,
.page-section-two-col-with-header .page-section-cell.header a:focus {
  color: var(--color-cobalt-blue-500);
  transition-duration: var(--speed-extra-fast);
}

.page-section-two-col-with-header .page-section-cell.header a:focus {
  transition-property: none;
}

/* ----------------------------------------------- */
a.page-section-cell-hero-image {
  aspect-ratio: 16 / 9;
  display: block;
  margin-bottom: calc(var(--gutter) / 2);
  overflow: hidden;
  position: relative;
}

.page-section-cell-hero-image img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  /* display: block; */
  height: var(--media-size);
  left: var(--media-position);
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: var(--media-position);
  transition-duration: var(--speed-fast);
  transition-property: opacity;
  transition-timing-function: var(--easing-color-or-opacity);
  width: var(--media-size);
  z-index: 0;
}

/* ----------------------------------------------- */

@keyframes hero-image-shimmer {
  0% {
    opacity: 0.4;
    transform: translateX(-94%);
  }

  100% {
    opacity: 0;
    transform: translateX(94%);
  }
}

a.page-section-cell-hero-image::after {
  --animation-device-size: 114%;
  --position-xy: calc(50% - var(--animation-device-size) / 2);
  --vertical-offset: 0;
  --animation-device-stage-position: -94%;
  --animation-device-color: var(--color-white);

  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 1.2s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-name: hero-image-shimmer;
  animation-play-state: paused;
  animation-timing-function: var(--easing-snap);
  background-color: var(--animation-device-color);
  clip-path: polygon(45% 0, 100% 0, 55% 88%, 0 88%);
  content: "";
  height: var(--animation-device-size);
  left: var(--position-xy);
  opacity: 0.4;
  pointer-events: none;
  position: absolute;
  top: calc(var(--position-xy) + var(--vertical-offset));
  transform: translateX(var(--animation-device-stage-position));
  width: var(--animation-device-size);
}

a.page-section-cell-hero-image:hover::after {
  animation-play-state: running;
}

a.page-section-cell-hero-image:not(:hover)::after {
  animation: none;
}

/* ----------------------------------------------- */
.person-signature,
.person-signature-cell {
  --headshot-image-size: 100px;
}

.person-signature {
  align-items: center;
  column-gap: var(--gutter);
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: calc(var(--gutter) / 2);
  margin-top: calc(var(--gutter) / 4);
  place-content: center flex-start;
}

.person-signature-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.person-signature-cell.headshot {
  aspect-ratio: 1 / 1;
  flex-basis: var(--headshot-image-size);
  flex-grow: 0;
}

/* ----------------------------------------------- */
.jax-signature {
  color: var(--color-black);
  fill: currentcolor;
  width: 200px;
}
